<template>
  <div class="role-list">
    <div class="outer">
      <h3 class="suppliy-info-title">
        商家信息
      </h3>
      <el-row
        :gutter="20"
        class="content"
      >
        <el-col :span="6">
          <div class="margin-b20">
            手机号：{{ supplierInfo.mobile }}
          </div>
        </el-col>
        <el-col :span="6">
          <div class="margin-b20">
            商家名称：{{ supplierInfo.suppliersName }}
          </div>
        </el-col>
        <el-col :span="6">
          <div class="margin-b20">
            所属区域：{{ supplierInfo.region }}
          </div>
        </el-col>
        <el-col :span="6">
          <div class="margin-b20">
            所属展馆：{{ supplierInfo.site }}
          </div>
        </el-col>
        <el-col :span="6">
          <div class="margin-b20">
            商品类型：{{ supplierInfo.goodsTypeName }}
          </div>
        </el-col>
        <el-col :span="6">
          <div class="margin-b20">
            入驻时间：{{ setTime(supplierInfo.settleTime) }}
          </div>
        </el-col>
        <el-col :span="12">
          <div class="margin-b20">
            状态：{{ supplierInfo.status === 4 ? '启用': '禁用' }}
          </div>
        </el-col>
        <el-col :span="6">
          <div class="margin-b20">
            入驻平台：{{ supplierInfo.mainPlatformName }}
          </div>
        </el-col>
        <el-col :span="12">
          <div class="margin-b20">
            签约入驻平台：{{ supplierInfo.applicationPlatformName || '/' }}
          </div>
        </el-col>
        <el-col :span="6">
          <div>
            <el-button
              type="primary"
              size="mini"
              @click="toSupplierDetail('read')"
            >
              查看信息
            </el-button>
            <el-button
              type="primary"
              size="mini"
              @click="toSupplierDetail('edit')"
            >
              编辑信息
            </el-button>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="outer margin-t20">
      <search-panel
        show-btn
        flex
        @getList="getList"
      >
        <div>
          <p>店铺名称</p>
          <el-input
            v-model="storeName"
            clearable
            placeholder="请输入名称"
            size="mini"
          />
        </div>
        <div>
          <p>创建开始日期</p>
          <el-date-picker
            v-model="tagtime"
            size="mini"
            type="datetimerange"
            align="right"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
          />
        </div>
      </search-panel>
    </div>
    <div class="outer margin-t20">
      <div>
        <el-button
          v-if="tableData.length == 0"
          type="primary"
          @click="operation('add')"
        >
          新增
        </el-button>
      </div>
      <el-table
        v-loading="loading"
        :data="tableData"
        border
        :header-cell-style="{'background': '#D9DDE1', 'color': '#0D0202', 'font-weight': 400}"
        element-loading-text="拼命加载中"
        style="width: 100%"
      >
        <el-table-column
          prop="id"
          label="ID"
          width="90"
        />
        <el-table-column
          prop="storeName"
          label="店铺名称"
        />
        <el-table-column
          prop="mobile"
          label="咨询电话"
        />
        <el-table-column
          prop="createTime"
          label="创建时间"
        />
        <el-table-column
          label="状态"
          width="120"
        >
          <template slot-scope="scope">
            {{ ['启用','禁用'][Number(scope.row.state)] }}
          </template>
        </el-table-column>
        <el-table-column
          prop="status"
          label="操作"
          width="250"
        >
          <template slot-scope="scope">
            <div>
              <el-button
                v-if="scope.row.state"
                type="success"
                size="mini"
                @click="disableOperation('enable', scope.row)"
              >
                启用
              </el-button>
              <el-button
                v-if="!scope.row.state"
                type="warning"
                size="mini"
                @click="disableOperation('disable', scope.row)"
              >
                禁用
              </el-button>
              <el-button
                type="primary"
                size="mini"
                @click="operation('edit', scope.row)"
              >
                编辑
              </el-button>
              <el-button
                type="success"
                size="mini"
                @click="operation('read', scope.row)"
              >
                查看
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        v-if="tableData && tableData.length > 0"
        :current-page="pagination.currentPage"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pagination.nowPageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.count"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { confirm } from '@/assets/js/confirmUrl';

export default {
  name: 'role-list',
  data() {
    return {
      storeName: '',
      user_mobile: '',
      order_sn: '',
      mobile: '',
      tableData: [],
      legal_person: '',
      loading: false,
      createStartTime: null,
      createEndTime: null,
      tagtime: [],
      bankNumber: '', // 账号
      merchantsName: '', // 商家名称
      merchantsNameArr: [
        {
          label: '全部',
          value: -1,
        },
        {
          label: '启用',
          value: 0,
        },
        {
          label: '禁用',
          value: 1,
        },
      ], // 商家列表
      multipleSelection: [], // 多选
      multipleSelectShow: false, // 多选控制器
      // 分页
      pagination: {
        currentPage: 1,
        nowPageSize: 10,
        count: 0,
      },
      supplierInfo: '',
      goodsTypeArr: ['农产品', '民宿', '景点旅游'],
      merchantsStatusArr: [
        {
          label: '新注册',
          value: '0',
        },
        {
          label: '初始化',
          value: '1',
        },
        {
          label: '审核中',
          value: '2',
        },
        {
          label: '启用',
          value: '3',
        },
        {
          label: '启用',
          value: '4',
        },
      ],
    };
  },
  watch: {
    tagtime(item) {
      if (item) {
        this.createStartTime = item[0];
        this.createEndTime = item[1];
      } else {
        this.createStartTime = null;
        this.createEndTime = null;
      }
    },
  },
  mounted() {
    this._getTypes();
    this.getInfo();
    this.getList();
  },
  methods: {
    async _getTypes() {
      const res = await this.$axios.get(this.$api.supplierInfoManage.getQualificationList);
      if (res.code === 0) {
        this.goodsTypeArr = res.data.reduce((pre, curr) => {
          if (!pre[curr.suppliersType]) pre[curr.suppliersType] = curr.suppliersTypeName;
          return pre;
        }, {});
      }
    },
    // 分页控制
    handleSizeChange(val) {
      this.pagination.nowPageSize = val;
      this.pagination.currentPage = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.getList();
    },
    getList() {
      this.loading = true;
      this.$axios({
        method: 'post',
        url: this.$api.store.list,
        data: {
          currentPage: this.pagination.currentPage,
          pageSize: this.pagination.nowPageSize,
          storeName: this.storeName,
          startTime: this.createStartTime ? this.createStartTime : null,
          endTime: this.createEndTime ? this.createEndTime : null,
        },
      }).then((res) => {
        if (res.code === 0) {
          this.tableData = res.data.records;
          this.pagination.count = Number(res.data.total);
        } else {
          this.$message(res.error_msg);
        }
        this.loading = false;
      });
    },
    // 获取商家信息
    getInfo() {
      this.$axios({
        method: 'post',
        url: this.$api.supplier.mine,
      }).then((res) => {
        if (res.code === 0) {
          this.supplierInfo = res.data;
          this.supplierInfo.goodsTypeName = this.supplierInfo.supplierGoodsType.split(',').filter((item) => item).reduce((pre, curr) => {
            pre.push(this.goodsTypeArr[curr]);
            return pre;
          }, []).join(', ');
        } else {
          this.$message(res.error_msg);
        }
        this.loading = false;
      });
    },
    // 禁用和启用
    disableOperation(type, row) {
      console.log('row', row);
      this.$confirm('确定修改此角色状态吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        if (type === 'disable') {
          setRoleDisable({ roleId: row.roleId }).then((res) => {
            if (res.code === 0) {
              this.$message.success('操作成功');
              this.getList();
            }
          });
        } else {
          setRoleEnable({ roleId: row.roleId }).then((res) => {
            if (res.code === 0) {
              this.$message.success('操作成功');
              this.getList();
            }
          });
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作',
        });
      });
    },
    operation(type, row) {
      if (type === 'read') {
        this.$router.push({ path: '/nb/setting/store/detail', query: { type, id: row.storeId } });
      } else if (type === 'edit') {
        this.$router.push({ path: '/nb/setting/store/detail', query: { type, id: row.storeId } });
      } else if (type === 'add') {
        this.$router.push({ path: '/nb/setting/store/detail', query: { type } });
      }
    },
    // 状态改变
    changeState(item) {
      this.orderStatus = item.name;
      this.getListChange();
    },
    changesTime() {
      if (this.begainValue) {
        this.sTime = Date.parse(this.begainValue) / 1000;
      } else {
        this.sTime = '';
      }
    },
    changeeTime() {
      if (this.endValue) {
        this.eTime = Date.parse(this.endValue) / 1000;
      } else {
        this.eTime = '';
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log('多选', this.multipleSelection);
    },
    // 提交删除
    multipleDelSubmit() {
      this.toDelete();
    },
    // 禁用和启用
    disableOperation(type, row) {
      console.log('row', row);
      this.$confirm('确定修改此账号状态吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        if (type === 'disable') {
          this.$axios({
            method: 'post',
            url: this.$api.store.disable,
            data: {
              storeId: row.storeId,
              state: row.state,
            },
          }).then((res) => {
            if (res.code === 0) {
              this.$message.success('操作成功');
              this.getList();
            }
          });
        } else {
          this.$axios({
            method: 'post',
            url: this.$api.store.enable,
            data: {
              storeId: row.storeId,
              state: row.state,
            },
          }).then((res) => {
            if (res.code === 0) {
              this.$message.success('操作成功');
              this.getList();
            }
          });
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作',
        });
      });
    },
    toSupplierDetail(type) {
      sessionStorage.setItem('supplier_region', this.supplierInfo.region);
      sessionStorage.setItem('supplier_site', this.supplierInfo.site);
      this.$router.push({
        path: '/nb/setting/merchants/detail',
        query: {
          id: this.supplierInfo.suppliersId,
          type,
          stepsVisible: '0',
        },
      });
    },
    setTime(time) {
      return time ? dayjs(Number(time)).format('YYYY-MM-DD HH:mm:ss') : '-';
    },
    outList() {
      let message = '是否确认导出数据';
      let url = `${this.$api.storeUser.getlist}?download=1&contact=${this.name}&contact_number=${this.mobile}&city_name=${this.city}`;
      confirm(message, url);
    },
  },
};
</script>

<style scoped lang="scss">
.suppliy-info-title{
  font-weight: 600;
  padding-bottom: 20px;
  font-size: 16px;
}
.content {
  font-size: 14px;
}
</style>
